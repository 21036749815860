<script>
    import {
        createYouTubeScript,
        destroyYouTubeScript,
        onYouTubeIframeAPIReady,
    } from "@/utils";

    export default {
        name: "ModalVideo",
        props: {
            videoId: String,
            autoplay: {
                type: Boolean,
                default: false,
            },
        },
        mounted() {
            this.$nextTick(() => {
                createYouTubeScript({ videoId: this.videoId, name: "modal-video" }, () => {
                    onYouTubeIframeAPIReady(
                        { videoId: this.videoId, id: "modal-video" },
                        (event) => {
                            if (this.autoplay) {
                                event.target.playVideo();
                            }
                            if (this.$refs.modalVideo) this.$refs.modalVideo.focus();
                        }
                    );
                });
            });
        },
        beforeDestroy() {
            destroyYouTubeScript("modal-video");
        },
    };
</script>

<template>
    <div class="modal-video" ref="modalVideo" tabindex="0">
        <div class="modal-video__media" id="modal-video">
            <preloader :show="true" is-color lg-size></preloader>
        </div>
    </div>
</template>

<style lang="scss">
    .modal-video {
        position: relative;
        border-radius: var(--modal-radius);
        overflow: hidden;
        outline: none;

        &:before {
            content: "";
            display: block;
            padding-bottom: 56.25%;
        }
        iframe {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>
